<template>
  <div class="ClassList">
      <Head />
      <div class="container">
        <el-table
          :data="tableData2"
          style="width: 100%"
          :row-class-name="tableRowClassName"
           @row-click="rowClik"
          >

          <el-table-column
            prop="name"
            label="Course Name"
            width="300"
            >
          </el-table-column>
          <el-table-column
            prop="session_description"
            label="Course Description"
            width="500"
            >
          </el-table-column>
          <el-table-column
            prop="sessionLen"
            label="Course hours">
          </el-table-column>
          <el-table-column
            prop="sessionNum"
            label="Number of Course Contents">
          </el-table-column>
          <!-- <el-table-column >
            <template slot-scope="scope">
              <el-button type="primary" style="width:40%;" @click="toSummary(scope.row.seession_id)" >打印</el-button>

            </template>
          </el-table-column> -->
        </el-table>
      </div>
  </div>
</template>

<script>
import Head from '@/components/Head'
import { getSessions } from '@/service.js'
import Cookie from 'js-cookie'

export default {
  name: 'SessionList',
  components: {
    Head,
  },
  mounted() {
    // const studentid = Cookie.get('studentid')
    this.class_id = this.$router.currentRoute.query.class_id
    this.required = this.$router.currentRoute.query.required
    const obj = this.required==1 ? {
      class_id: this.class_id,
      student_id: Cookie.get('studentid')
    }:{
      class_id: this.class_id,
    }
    console.log(this.required==1,Cookie.get('studentid'),obj)

    getSessions(obj).then((data) => {
      console.log(data)
      this.session_data = data
      data.map((i,index) => {
        this.tableData2.push({
          name: i.session_info.session_name,
          sessionNum: i.session_first_content_count,
          sessionLen:parseInt(i.session_length/60)+"'"+(i.session_length%60)+'"',
          seession_id: i._id.$id,
          session_description: i.session_info.session_description,
          index,
        })
      })
      this.tableData2 = [...this.tableData2]
      console.log("🚀 ~ getSessions ~ this.tableData2:", this.tableData2)
      console.log(this.tableData2)
    })
  },
  methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        let flag = true
        if (row.index != 0 && this.required == 1){
          flag = (this.session_data[row.index-1].quiz_count == this.session_data[row.index-1].answer_count)
        }
        if(row.index == 0 || flag){
          this.$router.push({ path: 'Home', query: { seession_id: row.seession_id }})

        }else{
          this.$message.error('请完成上个session');
        }

      },
      toSummary(e){
        this.$router.push({ path: 'Summary', query: { seession_id: e }})
      }
    },
    data() {
      return {
        tableData2: [],
        class_id: '',

      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cell{
  word-break: keep-all;
}
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
    .container{
      margin-top: 65px;
    }
  }
</style>
