import axios from 'axios'

/** 查看书籍详情 */
export const getClass = ({ class_id }) => {
  return axios
    .post("./index.php", {
      function: "getClass",
      class_id,
    })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      console.log("error", error)
    })
}
