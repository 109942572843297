<template>
  <div class="steps">
    <el-steps :active="active">
      <el-step v-for="(item, index) in dataList" :key="index" :title="item.block_info.block_name">
        <!-- <template slot="description">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.label"
            placement="top"
          >
            <div class="steps-desc" v-html="item.block_info.html_text"></div>
          </el-tooltip>
        </template> -->
      </el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      },
    },
    quiz: {
      type: Array,
      default: () => {
        return []
      },
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {
    handleTitle(num) {
      return `QS: ${num ? num : 0}`
    },
  },
  mounted() { },
}
</script>
<style scoped lang="scss">
.steps {
  width: 100%;
  margin: 20px 0;

  &-desc {
    cursor: pointer;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// .el-steps--horizontal .el-step__line {
//   margin-left: 100px;
// }
// .el-steps--horizontal .el-step__line:last-child {
//   margin-right: 100px;
// }
/deep/ .el-step__title {
  width: 200px;
  /* 设置固定长度 */
  line-height: unset;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.el-step__description {
  // width: 200px; /* 设置固定长度 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
