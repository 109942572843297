<template>
  <div class="Home">

    <Head />
    <div class="Home-container">
      <div class="container-left">
        <el-tree :data="menuItems" :props="defaultProps" @node-click="handleNodeClick"
          :render-content="renderContent"></el-tree>
      </div>
      <div class="container-right">
        <div>
          <el-container class="title">
            {{ content.content_name || "请选择content课程~" }}
            <span class="title-description" v-if="content_quiz.length !== 0">
              ({{ content_quiz.length }} practice questions for this
              course)</span>
            <el-button size="mini" type="success" style="margin-left: 10px" @click="beginQuiz"
              v-if="content_quiz.length !== 0" v-show="!onDoing" :disabled="content_quiz.length == 0">Do
              exercises</el-button>
            <el-button size="mini" type="success" style="margin-left: 10px" @click="endQuiz"
              v-if="content_quiz.length !== 0" v-show="onDoing">Review the course</el-button>
            <el-button size="mini" type="success" style="margin-left: 10px" @click="resetQuiz"
              v-if="content_quiz.length !== 0" v-show="onDoing">Delete wrong answer</el-button>
          </el-container>

          <Quiz :studentId="studentId" :quizs="quizs" :onDone="onQuizDone" :onCI="onChangeDone"
            :onConfirm="onConfirm" />

          <div v-if="content.content_name" class="content_instruction">
            {{ content.content_instruction || "" }}
          </div>

          <el-collapse v-model="activeNames">
            <el-collapse-item title="附件：" name="1" v-show="content_attach.length !== 0">
              <div v-for="item in content_attach" :key="item.attach_key">
                <a class="attach" target="_blank" :href="'//' + item.attach_address" :download="item.attach_title">{{
                  item.attach_title }}</a>
              </div>
              <!-- <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div> -->
            </el-collapse-item>
          </el-collapse>

          <div v-if="content.content_type === 'AUDIO'" style="height: calc(100% - 60px)">
            <audio :src="content.audio_url" controls controlsList="nodownload" style="margin-top: 10px"></audio>
            <div v-if="content.html_text" v-html="content.html_text"></div>
          </div>

          <div v-if="content.content_type === 'HTML'" style="height: calc(100% - 60px)">
            <div v-if="content.html_text" v-html="content.html_text"></div>
            <div style="width: 100%; height: 100%" v-if="!content.html_text && content.pdf_url">
              <pdf :src="content.pdf_url" width="100%" height="100%" v-for="i in numPages" :key="i" :page="i"
                style="width: 100%" @page-loaded="pageloaded" />
            </div>
            <div v-if="!content.html_text && !content.pdf_url" style="margin-top: 20px">
              Coming soon.
            </div>
          </div>

          <div v-if="content.content_type === 'VIDEO'">
            <div>
              <video :src="content.video_url" id="videoPlay" controls="controls" class="video"
                oncontextmenu="return false;" v-show="!isBeginQuiz && !onDoing" @timeupdate="timeupdate"
                controlslist="nodownload" @ended="videoEnd">
                您的浏览器不支持 video 视屏播放。
              </video>
            </div>
          </div>

          <div v-if="content.content_name">
            <div class="addCommets">
              <el-input style="margin-top: 10px;" type="textarea" v-model="educator_comment"
                placeholder="Please write down your questions if you have… "></el-input>
              <el-select v-model="now_index" placeholder="Please select the question">
                <el-option label="Course Contents" :value="0"></el-option>
                <el-option v-for="(item, index) in content_quiz" :label="item.question_title" :key="item._id.$id"
                  :value="index + 1" class="option"></el-option>
              </el-select>
              <el-button style="margin-top: 20px;margin-left: 20px;" @click="submitComment">Submit</el-button>
            </div>
            <div v-if="showComment" class="comments">
              <!-- <div style="margin-bottom:20px">我的内容：{{my_block}}</div> -->
              课堂答疑：{{ comments.length === 0 ? "无" : "" }}
              <div v-for="item in comments" :key="item._id.$id" style="margin-top: 10px; border-bottom: 1px solid #ccc">
                {{ item.account_name }}-{{ item.sender_name }}:
                {{ item.message_content }}
                <span style="color: #ccc; font-size: 12px; padding: 5px">{{
                  new Date(item.create_time * 1000).toLocaleDateString()
                }}</span>
                <div style="font-size: 12px; padding: 16px; color: #999">
                  {{ item.reply_text }}
                </div>
              </div>
            </div>
          </div>
          <!-- 进度条 -->
          <Steps style="margin-top: 30px;" :dataList="stepList" :active="active" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getContent, getContents, netService, resetQuiz } from "@/service.js"
import Cookie from "js-cookie"
import pdf from "vue-pdf"

import Head from "@/components/Head"
import Quiz from "@/components/Quiz"
import Steps from "./components/Steps"
export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    Head,
    Quiz,
    pdf,
    Steps,
  },
  data() {
    return {
      //先隐藏
      showComment: false,
      drawer: true,
      now_index: "",
      educator_comment: "",
      comments: [],
      now_content_id: "",
      videoDone: false,
      videoElement: null,
      session_id: "",
      menuItems: [],
      stepList: [],
      active: 0,
      isShowStep: false,
      defaultProps: {
        children: "children",
        label: "label",
        content_info: "content_info",
      },
      content: {},
      studentId: "",
      quizs: [],
      isBeginQuiz: false,
      quizsByTimes: {},
      content_quiz: [],
      onDoing: false,
      activeNames: ["1"],
      content_attach: [],
      quizIndex: 0,
      numPages: 0,
    }
  },
  watch: {
    isBeginQuiz() {
      console.log("变化了begin")
    },
    onDoing() {
      console.log("变化了onDoing")
    },
  },
  mounted() {
    this.session_id = this.$router.currentRoute.query.seession_id
    this.studentId = Cookie.get("studentid")
    getContents({
      session_id: this.session_id,
      student_id: this.studentId,
    }).then((data) => {
      this.menuItems = this.handleList(data)
      this.class_id = this.menuItems[0].content_info.class_id
      this.handleNodeClick(this.menuItems[0])
      // 渲染进度条 进度条用block
      // this.handleSetSteps()
    })
  },
  methods: {
    /**设置进度条相关数据 */
    handleSetSteps() {
      this.stepList = []
      this.active = 0
      this.menuItems.map((item) => {
        if (!item.children.length) {
          this.stepList.push(item)
        } else {
          this.stepList = [...this.stepList, ...item.children]
        }
      })
      this.stepList &&
        this.stepList.map(async (item, index) => {
          await this.handleSetQuiz(item)
          this.isShowStep = this.stepList.length - 1 === index
        })
    },
    async handleSetQuiz(data, quizList = [], isDone = true) {
      try {
        const res = await getContent({
          content_id: data.content_info.id,
          student_id: this.studentId,
        })

        res.content_quiz.map((v) => {
          if (quizList[+v.quiz_position]) {
            quizList[v.quiz_position].push(v)
          } else {
            quizList[v.quiz_position] = [v]
          }
        })
        const problem = []
        for (const index in quizList) {
          problem.push(...quizList[index])
        }
        if (problem?.length > 0) {
          for (let i = 0; i < problem.length; i++) {
            if (!isDone) break
            isDone = problem[i]?.quiz_answer !== undefined
          }
        }
        //是否有题目，有题目是否已完成
        problem.length ? (isDone ? this.active++ : this.active) : this.active++
        data.isDone = isDone
        data.problem = problem.length || 0
      } catch (e) {
        console.log("🚀 ~ handleSetQuiz ~ e:", e)
      }
    },
    resetQuiz() {
      this.$confirm("Delete question record?", "Reminder", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          resetQuiz(this.studentId, this.class_id, this.session_id).then(
            (res) => {
              if (res.code === 0) {
                this.onConfirm()
                this.$message({
                  message: "删除成功",
                  type: "success",
                })
              }
              location.reload()
            }
          )
        })
        .catch(() => { })
    },
    pageloaded() {
      this.loading.close()
    },
    renderContent(h, { node, data }) {
      const isComplete = data.content_info.complete_status
      return (
        <span class="el-tree-node__label">
          <span title={node.label} class={isComplete === "yes" ? "green" : ""}>
            {node.label}
          </span>
        </span>
      )
      // {isComplete === 'yes'  ?(<i class="el-icon-check "></i>): ''}
    },
    async handleNodeClick(data) {
      this.stepList = []
      this.onDoing = false
      this.now_content_id = data.content_info.id
      if (data.content_info.content_type === "VIDEO") {
        getContent({
          content_id: data.content_info.id,
          student_id: this.studentId,
        }).then((data) => {
          this.quizsByTimes = {}
          this.isBeginQuiz = false
          this.quizs = []
          this.videoDone = false
          const quizsByTimes = this.quizsByTimes
          let content_quiz = data.content_quiz
          let reg = new RegExp("\r\n", "g")
          for (let item of content_quiz) {
            item.question_title = item.question_title.replace(reg, "</br>")
          }
          this.content_quiz = content_quiz

          data.content_quiz.map((v) => {
            if (quizsByTimes[+v.quiz_position]) {
              quizsByTimes[v.quiz_position].push(v)
            } else {
              quizsByTimes[v.quiz_position] = [v]
            }
          })
          //   this.question = res.data.question_title.replace(reg, "</br>")
          this.content_attach = data.content_attach
          this.stepList = data.blocks
          // this.quizs = [...data.content_quiz]
          // quizsByTimes
        })
      } else {
        getContent({
          content_id: data.content_info.id,
          student_id: this.studentId,
        }).then((data) => {
          this.stepList = data.blocks
          this.quizsByTimes = {}
          this.isBeginQuiz = false
          this.quizs = []
          this.videoDone = false
          const quizsByTimes = this.quizsByTimes

          let content_quiz = data.content_quiz
          let reg = new RegExp("\r\n", "g")
          for (let item of content_quiz) {
            item.question_title = item.question_title.replace(reg, "</br>")
          }
          this.content_quiz = content_quiz
          data.content_quiz.map((v) => {
            if (quizsByTimes[+v.quiz_position]) {
              quizsByTimes[v.quiz_position].push(v)
            } else {
              quizsByTimes[v.quiz_position] = [v]
            }
          })
          this.content_attach = data.content_attach
          if (
            this.content.html_text ==
            '<div class="ql-editor ql-blank" data-gramm="false" contenteditable="true"><p><br></p></div><div class="ql-clipboard" contenteditable="true" tabindex="-1"></div><div class="ql-tooltip ql-hidden"><a class="ql-preview" target="_blank" href="about:blank"></a><input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL"><a class="ql-action"></a><a class="ql-remove"></a></div>'
          ) {
            this.content.html_text = ""
          }

          if (!this.content.html_text && this.content.pdf_url) {
            const loadingTask = pdf.createLoadingTask(this.content.pdf_url)
            this.loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            })

            loadingTask.promise.then((pdf) => {
              this.numPages = pdf.numPages
            })
          }
        })
      }
      // getContent({content_id: data.content_info.content_id}).then((i) => {
      this.content = data.content_info
      this.getSB()
      // })
    },
    handleList(list) {
      let result = []
      list.map((v) => {
        this.dfs(result, v)
      })
      return result
    },
    dfs(result, v) {
      if (+v.content_info.content_level === 1) {
        result.push({
          label: v.content_info.content_name,
          children: [],
          content_info: {
            ...v.content_info,
            id: v._id.$id,
            complete_status: v.complete_status,
          },
        })
      } else {
        const len = result.length
        const l = +v.content_info.content_level
        v.content_info.content_level = l - 1
        v.content_info.content_raw_level = l
        this.dfs(result[len - 1].children, v)
      }
    },
    onQuizDone() {
      this.isBeginQuiz = false
      this.quizs = []
      this.onDoing = false

      if (this.videoDone) {
        setTimeout(() => {
          this.videoElement.play()
          this.videoDone = false
        }, 300)
      }
    },
    onChangeDone(index) {
      this.quizIndex = index
    },
    onConfirm() {
      getContents({
        session_id: this.session_id,
        student_id: this.studentId,
      }).then((data) => {
        this.menuItems = this.handleList(data)
        this.handleSetSteps()
      })
    },
    timeupdate(event) {
      // debugger
      const currentTime = event.target.currentTime //获取当前播放时间
      this.videoElement = event.target
      if (this.quizsByTimes[~~currentTime]) {
        this.quizs = this.quizsByTimes[~~currentTime]
        this.onDoing = true
        setTimeout(() => {
          this.videoElement.pause()
        }, 1000)
        this.isBeginQuiz = true
      }
    },
    videoEnd() {
      if (this.quizsByTimes[-1]) {
        this.quizs = this.quizsByTimes[-1]

        this.isBeginQuiz = true
        this.videoDone = true
        this.onDoing = true
      }
    },
    submitComment() {
      if (this.educator_comment == "") {
        this.$message({
          message: "内容不能为空",
          type: "error",
          duration: 1500,
        })
        return
      }
      if (this.now_index == "") {
        this.$message({
          message: "内容归属为空",
          type: "error",
          duration: 1500,
        })
        return
      }
      // let index = 0
      // if(this.onDoing){
      //   index = this.quizIndex + 1
      // }
      netService({
        functionName: "sendMessage",
        student_id: this.studentId,
        question_id: this.now_content_id,
        text: this.educator_comment,
        index: this.now_index,
      }).then((data) => {
        if (data == 1) {
          this.educator_comment = ""
          this.$message({
            message: "添加成功~",
            type: "success",
            duration: 1500,
          })
          this.getSB()
        }
      })
    },
    handleClose(closeDrawer) {
      closeDrawer()
    },
    getSB() {
      netService({
        functionName: "findQuestionMessages",
        question_id: this.now_content_id,
      }).then((data) => {
        this.comments = JSON.parse(data)
        // this.update_block_content = data.block_content
        // this.comments = data.block_comments
        // const date = new Date(data.latest_save_time*1000)
        // this.latest_save_time = data.latest_save_time ? date.toLocaleDateString() + ' '+date.toLocaleTimeString() : '没有记录~'
        // this.isAnswer = data._id ? true: false
        // this.student_block_id = data._id.$id
      })
    },
    beginQuiz() {
      this.onDoing = true
      let test = []
      for (const index in this.quizsByTimes) {
        test.push(...this.quizsByTimes[index])
      }
      this.quizs = test
      this.isBeginQuiz = !this.isBeginQuiz
    },
    endQuiz() {
      this.onDoing = false
      let test = []

      this.quizs = test
      this.isBeginQuiz = !this.isBeginQuiz
    },
  },
}
</script>

<style scoped lang="scss">
.attach {
  color: #409eff;
  display: block;
  text-decoration: wavy;
}

.comments {
  margin-top: 30px;
}

.Home {
  width: 100%;

  .HomeButton {
    position: fixed;
    top: 10px;
    left: 10px;
    background: #b3c0d1;
    z-index: 100;
  }

  .Home-container {
    display: flex;
    margin-top: 60px;
    height: calc(100vh - 65px);

    .container-left {
      padding: 10px;
      width: 320px;
      box-sizing: border-box;
      height: calc(100vh -65px);
      background: #eef1f6;
      overflow: auto;

      /deep/ .el-tree-node {
        white-space: break-spaces;
        background: rgb(238, 241, 246);
      }
    }

    .container-right {
      height: calc(100vh -65px);
      flex: 1;
      padding: 20px 20px;
      box-sizing: border-box;
      overflow-y: auto;

      .main-container {
        font-size: 24px;
        padding: 10px;
      }
    }
  }
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-tree-node {
  padding: 15px 0;
}

.title {
  font-size: 20px;
}

#videoPlay {
  width: 100%;
}

.title-description {
  font-size: 14px;
  display: contents;
}

.content_instruction {
  display: block;
  font-size: 12px;
  line-height: 150%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.option {
  width: 80%;
}

.el-tree-node__label {
  /* overflow: hidden;
    text-overflow: ellipsis; */
}

.el-tree-node__expand-icon+.el-tree-node__label {
  font-weight: bold;
}

.el-tree-node__expand-icon.is-leaf+.el-tree-node__label {
  font-weight: normal;
}

.green {
  color: green;
}

.el-tree-node {
  white-space: break-spaces;
  background: rgb(238, 241, 246);
}
</style>
<style>
/* .el-popper{
  width: 95%!important;
} */
.color {
  background: #eef1f6;
  overflow: scroll;
}

.el-tree-node__content {
  padding: 15px 0;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}

.addCommets .el-textarea__inner {
  height: 100px;
}
</style>
