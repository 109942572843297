<template>
  <div>
    <el-button class="pdf" @click="p($event)" >生成PDF报告</el-button>
    <h1>{{classname}}</h1>
    <div v-for="(contentss, index) in sessions" :key="index">
    <el-container class="main-container" v-for="content in contentss" :key="content.class_id">


        <el-main style="font-family: 'Amiri',serif;overflow: unset;">
          <!-- <el-table :data="tableData">
            <el-table-column prop="date" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地址">
            </el-table-column>
          </el-table> -->
          <el-container class="title">
            <h1>{{content.content_name}}</h1>

            <!-- <span class="title-description" v-if="content_quiz.length!==0"> (本课程有{{content_quiz.length}}道随堂练习)</span> -->
          </el-container>
          <h3 v-if="content.content_name" class="content_instruction">{{content.content_instruction || ''}}</h3>
          <div v-if="content.content_type === 'HTML'" >
            <div v-if="content.html_text" v-html="content.html_text"></div>
            <div v-if="!content.html_text && !content.pdf_url" style="margin-top:20px">Coming soon.</div>
          </div>
          <div v-if="content.blocks">
            <div v-for="(block, blockIndex) in content.blocks" :key="blockIndex" class="block-style">
              <h3 v-html="block.block_info.block_name"></h3>
              <div v-html="block.block_info.html_text"></div>
            </div>
          </div>
          <QuizStatic  :quizs="content.quiz_array" />

        </el-main>
        <!-- <div class="console">
            console
        </div> -->
        <br />
        <br />
        <br />
        <br />
    </el-container>
    </div>
  </div>
</template>

<script>
import QuizStatic from '@/components/QuizStatic'
import { getContents, getSessions } from '@/service.js'
import Cookie from 'js-cookie'

export default {
  name: 'Summary',
  props: {

  },
  mounted(){
    this.class_id = this.$router.currentRoute.query.class_id
    this.classname = this.$router.currentRoute.query.class_name
    this.studentId = Cookie.get('studentid')
    this.getSessions().then(data => {
      console.log(data)
      for(const i of data){
        this.getContents(i._id.$id)
      }
    })
    this.getContents()
  },
  components: {
    QuizStatic
  },
  methods: {
    getSessions(){
      return getSessions({
        class_id: this.class_id
      }).then((data) => {
        return data
      })
    },
    getContents(session_id){

      getContents({
        session_id,
        student_id: this.studentId
        }).then((data) => {
          console.log(data)
          this.contents = data.map(v  => {
            v.content_info.quiz_array = v.quiz_array
            v.id = v._id.$id
            v.content_info.blocks = v.blocks
            return v.content_info
          })
          this.sessions.push(this.contents)
      })
    },

    p(e){
      console.log(e.path)

      e.path[1].style.display = 'none'
      document.title = this.classname
      window.print()
      e.path[1].style.display = 'block'


    },
  },
  data(){
    return {
      contents:[],
      sessions: [],
      classname: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pdf{
    position: fixed;
    top: 3%;

    right: 1%;
    background-color:#1863bb;
    color:white;

  }

.block-style {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
