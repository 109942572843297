<template>
    <div style="margin-top: 40px;width: 100%;padding: 15px;box-sizing: border-box;">
        <div v-for="(quiz, index) in quizList" :key="index">
            <div style="display: flex;align-items: center;">
                <p>Question</p>
                <div style="display: flex;flex-direction: row-reverse;flex: 1;gap: 15px;">
                    <i style="cursor: pointer;" class="el-icon-check" @click="performAction(index, 'save')"></i>
                    <i class="el-icon-delete" style="cursor: pointer;" @click="performAction(index, 'delete')"></i>
                    <!-- <i v-if="quiz.type == 'edit'" style="cursor: pointer;" class="el-icon-check" @click="performAction(index, 'save')"></i> -->
                    <!-- <i v-else class="el-icon-edit" style="cursor: pointer;" @click="performAction(index, 'edit')"></i> -->
                </div>
            </div>
            <el-input v-model="quiz.question_title" type="textarea" :rows="4" placeholder="Please input"></el-input>
            <p style="margin-top: 15px;">Options(Sperate with "|")</p>
            <el-input v-model="quiz.question_option_string" type="textarea" :rows="8"
                placeholder="Please input"></el-input>
            <p style="margin-top: 15px;">Answer</p>
            <el-input-number v-model="quiz.question_answer" placeholder="Please input"></el-input-number>
        </div>
    </div>
</template>
<script>
import { postRequest } from "@/service.js";
export default ({
    name: 'index',
    props: {
        block_id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            quizList: [],
        }
    },
    watch: {
        block_id: {
            handler(newVal, oldVal) {
                this.requesBlockQuizList();
            },
            immediate: true,
        },
    },
    methods: {

        performAction(index, type) {
            switch (type) {
                case 'delete':
                    this.$alert('你确定要删除该Quiz吗？', '提示', {
                        callback: action => {
                            if (action == 'confirm') {
                                let item = this.quizList[index]
                                this.doRemoveBlockQuiz(item._id.$id)
                            }
                        }
                    })
                    break;
                // case 'edit':
                //     this.quizList[index].type = 'edit'
                //     break;
                case 'save':
                    var quizInfo = this.quizList[index]
                    this.doUpdateBlockQuizInfo(quizInfo)
                    break;
            }
        },
        doRemoveBlockQuiz(quiz_id) {
            postRequest('removeBlockQuiz', {
                quiz_id: quiz_id
            })
                .then((response) => {
                    this.$message.success('删除成功')
                    this.requesBlockQuizList()
                })
                .catch((error) => {
                    // Handle error
                    console.error('Error updating block:', error);
                });
        },
        doUpdateBlockQuizInfo(blockQuizInfo) {
            if (blockQuizInfo.question_title == '' || blockQuizInfo.question_answer == '' || blockQuizInfo.question_option_string == '') {
                this.$message.warning('Please check question params')
                return
            }
            postRequest('updateBlockQuiz', blockQuizInfo)
                .then((response) => {
                    this.$message.success('提交成功')
                    this.requesBlockQuizList()
                })
                .catch((error) => {
                    // Handle error
                    console.error('Error updating block:', error);
                });
        },

        addNewQuiz() {
            this.quizList.push({
                type: 'edit',
                id: '',
                block_id: this.block_id,
                question_title: '',
                question_answer: '',
                question_option_string: ''
            })
        },

        requesBlockQuizList() {
            if (this.block_id == '') {
                return
            }
            this.blockQuizList = []
            let params = {
                block_id: this.block_id
            }
            postRequest('getAllBlockQuiz', params)
                .then((response) => {
                    let result = response.data
                    result.map((item) => {
                        item.type = 'edit'
                        return item
                    })
                    this.quizList = result
                })
                .catch((error) => {
                    // Handle error
                    console.error('Error updating block:', error);
                });
        },
    }
})
</script>
