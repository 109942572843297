<template>
  <div class="wrapper">
    <div class="wrapper-left">
      <img :src="classInfo.class_avatar_url" />
    </div>
    <div class="wrapper-right">
      <div class="desc">
        <span class="bold">ClassName：</span>
        {{ classInfo.class_name }}
      </div>
      <div class="desc">
        <span class="bold">Description：</span> {{ classInfo.class_description }}
      </div>
    </div>
  </div>
</template>

<script>
import { getClass } from "@/apis/front.js";
export default {
  data() {
    return {
      classInfo: {},
    }
  },
  methods: {},
  mounted() {
    const id = this.$router.currentRoute.query.class_id;
    getClass({
      class_id: id,
    }).then((res) => {
      this.classInfo = res.class_info
    })
  },
}
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  padding: 60px 0;
  &-left {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 300px;
      height: 400px;
      margin: 0 auto;
      object-fit: contain;
    }
  }
  &-right {
    padding: 20px 20px;
    .desc{
      font-size:18px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .bold{
      font-size: 20px;
      font-weight: 900;
    }
  }
}
</style>
