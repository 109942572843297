<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-05-13 21:30:19
 * @LastEditTime: 2019-08-23 07:50:40
 * @LastEditors: Please set LastEditors
 -->
<template>
  <el-header class="header">
    <div class="header-right">
      <a href="/#/classList">Home Page</a>
      <el-dropdown>
        <i class="el-icon-setting" style="margin-right: 15px"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            ><div @click="toMessage()">Message Center</div></el-dropdown-item
          >
          <el-dropdown-item v-if="type !== 'student'"
            ><div @click="toSign()">Course Registration</div></el-dropdown-item
          >
          <el-dropdown-item><div @click="logout()">Exit</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span>{{ name }}</span>
    </div>
  </el-header>
</template>

<script>
import Cookie from "js-cookie"

export default {
  name: "Head",
  props: {},
  mounted() {
    console.log(this.$store.state.student_info)
  },
  computed: {
    name() {
      return Cookie.get("user_name")
    },
    type() {
      return Cookie.get("type")
    },
  },
  methods: {
    logout() {
      console.log("out")
      Cookie.remove("studentid")
      location.reload()
    },
    toMessage() {
      this.$router.push({ path: "message" })
    },
    toSign() {
      this.$router.push({ path: "signlist" })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  position: fixed;
  width: 100vw;
  z-index: 99;
  top: 0;
  text-align: right;
  font-size: 12px;
  &-right {
    margin-right: 10px;
    a {
      margin-right: 10px;
      color: black;
      text-decoration: none;
    }
  }
}
</style>
