<template>
  <div class="ClassList">
      <Head />
      <div class="container">
      <el-table
      :data="tableData2"
      style="width: 100%"
      :row-class-name="tableRowClassName"
      @row-click="rowClik"
      >
      <!-- <el-table-column >
        <template slot-scope="scope">
          <div style="text-align: center"><img :src="scope.row.img_url" alt="" class="avatar_url"></div>

        </template>
      </el-table-column> -->
      <el-table-column
        prop="class_BU"
        label="BU"
        width="200"
        >
      </el-table-column>
      <el-table-column
        prop="class_name"
        label="Course Name"
        >
      </el-table-column>
      <!-- <el-table-column
        prop="description"
        width="500"
        label="Course Description">
      </el-table-column> -->
      <!-- <el-table-column
        prop="sessionNum"
        label="Number of course">
      </el-table-column>
      <el-table-column
        prop="progress"
        label="Completion Progress">
      </el-table-column> -->
      <el-table-column v-if="login_type == 'admin'" width="200">
        <template slot-scope="scope">
          <el-button v-if="login_type === 'admin'" type="primary" @click="toSummary(scope.row.class_id, scope.row.name)">打印</el-button>
          <el-button type="primary" @click="toClassEdit(scope.row.class_id)">Edit</el-button>
          <el-button v-if="login_type === 'student'" type="primary" @click="toStudyProcess(scope.row.class_id, scope.row.class_quiz_count)">学习进度</el-button>

        </template>
      </el-table-column>
    </el-table>
      </div>
  </div>
</template>

<script>
import Head from '@/components/Head'
import { getAdminClasses, getStudentClasses } from '@/service.js'
import Cookie from 'js-cookie'

export default {
  name: 'ClassList',
  mounted() {
    const studentid = Cookie.get('studentid')
    this.login_type = Cookie.get('type')
    if(this.login_type == 'admin'){
      getAdminClasses({student_id: 'admin'}).then(data => {
        data.map(i => {
          this.tableData2.push({
            class_name: i.class_name,
            // sessionNum: i.class_info.session_ids ? i.class_info.session_ids.length : 0,
            class_id: i.class_id,
            class_BU:i.class_BU,
            // description: i.class_info.class_info.class_description,
            // img_url: i.class_info.class_info.class_avatar_url,
            // progress: i.class_quiz_done + '/' + i.class_quiz_count,
            // class_quiz_count: i.class_quiz_count
          })
          this.tableData2 = [...this.tableData2]
        })
      })
    }else{
      getStudentClasses({
        student_id: studentid
      }).then((data) => {
        data.map(i => {
          this.tableData2.push({
            class_BU:i.class_info.class_info.class_BU,
            class_name: i.class_info.class_info.class_name,
            // sessionNum: i.class_info.session_ids ? i.class_info.session_ids.length : 0,
            class_id: i.class_id,
            // description: i.class_info.class_info.class_description,
            // img_url: i.class_info.class_info.class_avatar_url,
            // progress: i.class_quiz_done + '/' + i.class_quiz_count,
            // session_completion_required: i.class_info.class_info.session_completion_required == '否'? '0':'1'
          })
          this.tableData2 = [...this.tableData2]
        })
      })
    }

  },
  components: {
    Head,
  },
  methods: {
      tableRowClassName({ rowIndex }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      rowClik(row) {
        console.log(row)
        this.$router.push({ path: 'sessionList', query: { class_id: row.class_id, required: row.session_completion_required }})
      },
      toSummary(e, name){
        this.$router.push({ path: 'Summary', query: { class_id: e, class_name:  name}})
      },
      toStudyProcess(e,count){
        console.log(count)
        this.$router.push({ path: 'StudyProcess', query: { class_id: e, count}})

      },
      toClassEdit(e){
        this.$router.push({ path: 'ClassEdit', query: { class_id: e}})
      }
    },
    data() {
      return {
        login_type:'',
        tableData2: [],
        tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style language="scss" scoped>
.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
.el-table{
      width: 80%!important;
    margin: 0 auto;
}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
  .ClassList{
    width: 100%;
    /* margin: 0 auto; */
    .container{
      margin-top: 65px;
    }
  }
  .avatar_url{
    width: 50px;
    height: 50px;
    transform: translate(0, 10%);
    /* border-radius: 50%;  */
  }
</style>
<style>
.el-table__row{
      cursor: pointer;
}
</style>
