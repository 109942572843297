<template>
  <div class="wrapper">
    <el-container>
      <el-header style="width: 100%; position: fixed; top: 0; left: 0; z-index: 1000;">
        <h2 v-if="!isClassNameEditing" @dblclick="startClassNameEditing" style="margin-top: 0px; cursor: pointer;">{{
          classInfo.class_info.class_name }}</h2>
        <el-input v-else v-model="editedClassName" @blur="saveClassName" @keyup.enter.native="saveClassName"
          style="margin-top: 0px;"></el-input>
      </el-header>
      <el-container style="margin-top: 60px;display: flex;">
        <div class="sidebar" style="width: 420px; height: calc(100vh - 60px); overflow-y: auto;">
          <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect"
            style="width: 100%;">
            <el-menu-item index="fold-unfold">
              <el-button @click="toggleFoldUnfold" type="text">
                {{ isMenuFolded ? 'Unfold All' : 'Fold All' }}
              </el-button>
            </el-menu-item>
            <el-submenu v-for="(session, index) in classContents" :key="index" :index="index.toString()">
              <template slot="title">
                <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                  <span v-if="!session.isEditing" class="menu-item-text"
                    style="white-space: normal; word-wrap: break-word; line-height: 1.2; padding: 10px; flex-grow: 1;">
                    {{ session.session_info.session_name }}
                  </span>

                  <el-input v-if="session.isEditing" v-model="session.editedSessionName" @blur="saveSessionName(index)"
                    @keyup.enter.native="saveSessionName(index)" ref="sessionNameInput" v-focus @keydown.enter.prevent
                    style="width: 100%;"></el-input>

                  <el-button @click.stop="doRemoveSession(index)" type="text" icon="el-icon-delete"
                    style="padding: 2px; margin: 0 1px;"></el-button>

                  <el-button v-if="!session.isEditing" @click.stop="startSessionEditing(index)" type="text"
                    icon="el-icon-edit" style="padding: 10px;"></el-button>
                  <el-button @click.stop="moveSessionUp(index)" type="text" icon="el-icon-top"
                    style="padding: 2px; margin: 0 1px;"></el-button>
                  <el-button @click.stop="moveSessionDown(index)" type="text" icon="el-icon-bottom"
                    style="padding: 2px; margin: 0 1px;"></el-button>
                </div>
              </template>
              <el-submenu v-for="(content, contentIndex) in session.contents" :key="`${index}-${contentIndex}`"
                :index="`${index}-${contentIndex}`">
                <template slot="title">
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                    <span v-if="!content.isEditing" class="menu-item-text"
                      style="white-space: normal; word-wrap: break-word; line-height: 1.2; padding: 10px; flex-grow: 1;">
                      {{ content.content_info.content_name }}
                    </span>
                    <el-input v-if="content.isEditing" v-model="content.editedContentName"
                      @blur="saveContentName(index, contentIndex)"
                      @keyup.enter.native="saveContentName(index, contentIndex)" ref="contentNameInput" v-focus
                      @keydown.enter.prevent style="width: 100%;"></el-input>

                    <el-button @click.stop="doRemoveContent(index, contentIndex)" type="text" icon="el-icon-delete"
                      style="padding: 2px; margin: 0 1px;"></el-button>

                    <el-button v-if="!content.isEditing" @click.stop="startContentEditing(index, contentIndex)"
                      type="text" icon="el-icon-edit" style="padding: 10px;"></el-button>
                    <el-button @click.stop="moveContentUp(index, contentIndex)" type="text" icon="el-icon-top"
                      style="padding: 2px; margin: 0 1px;"></el-button>
                    <el-button @click.stop="moveContentDown(index, contentIndex)" type="text" icon="el-icon-bottom"
                      style="padding: 2px; margin: 0 1px;"></el-button>

                  </div>
                </template>
                <el-menu-item v-for="(block, blockIndex) in content.blocks"
                  :key="`${index}-${contentIndex}-${blockIndex}`" :index="`${index}-${contentIndex}-${blockIndex}`"
                  @click="selectContentAndScrollToTop(block)">
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                    <span v-if="!block.isEditing" class="menu-item-text"
                      style="white-space: normal; word-wrap: break-word; line-height: 1.2; padding: 10px; flex-grow: 1;">
                      {{ block.block_info.block_name }}
                    </span>
                    <el-input v-if="block.isEditing" v-model="block.editedBlockName"
                      @blur="saveBlockName(index, contentIndex, blockIndex)"
                      @keyup.enter.native="saveBlockName(index, contentIndex, blockIndex)" ref="blockNameInput" v-focus
                      @keydown.enter.prevent style="width: 100%;"></el-input>

                    <el-button @click.stop="doRemoveBlock(index, contentIndex, blockIndex)" type="text"
                      icon="el-icon-delete" style="padding: 2px; margin: 0 1px;"></el-button>

                    <el-button @click.stop="startBlockEditing(index, contentIndex, blockIndex)" type="text"
                      icon="el-icon-edit" style="padding: 2px; margin: 0 1px;"></el-button>
                    <el-button @click.stop="moveBlockUp(index, contentIndex, blockIndex)" type="text" icon="el-icon-top"
                      style="padding: 2px; margin: 0 1px;"></el-button>
                    <el-button @click.stop="moveBlockDown(index, contentIndex, blockIndex)" type="text"
                      icon="el-icon-bottom" style="padding: 2px; margin: 0 1px;"></el-button>

                  </div>
                </el-menu-item>
                <el-menu-item @click="addNewBlock(index, contentIndex)">
                  <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                    <span class="menu-item-text"
                      style="white-space: normal; word-wrap: break-word; line-height: 1.2; padding: 10px; flex-grow: 1; color: gray;">
                      + Add A New Block in this Content
                    </span>
                  </div>
                </el-menu-item>
              </el-submenu>
              <el-menu-item @click="addNewContent(index)">
                <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                  <span class="menu-item-text"
                    style="white-space: normal; word-wrap: break-word; line-height: 1.2; padding: 10px; flex-grow: 1; color: gray;">
                    + Add A New Content in this Session
                  </span>
                </div>
              </el-menu-item>
            </el-submenu>
            <el-menu-item @click="addNewSession">
              <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                <span class="menu-item-text"
                  style="white-space: normal; word-wrap: break-word; line-height: 1.2; padding: 10px; flex-grow: 1; color: gray;">
                  + Add A New Session
                </span>

              </div>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="main-content"
          style="background-color: #f0f2f5; flex: 1; margin: 0 auto; padding: 0px; height: calc(100vh - 60px); overflow-y: auto;">
          <div v-if="selectedContent">
            <div @click="toggleEditMode"
              style="background-color: #e6f7ff; border-bottom: 3px solid #1890ff; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; position: sticky; top: 0; z-index: 1; padding: 10px;">
              <h3 v-if="!isEditing">{{ selectedContent.block_info.block_name }}</h3>
              <el-input v-else v-model="editedBlockName" @blur="saveBlockName2()" @keyup.enter.native="saveBlockName2()"
                ref="blockNameInput" v-focus @keydown.enter.prevent></el-input>
            </div>
            <div style="margin-top: 50px; padding: 30px;">
              <template v-if="!isContentEditing">
                <div v-if="selectedContent.block_info.html_text && !showSubDivs"
                  v-html="selectedContent.block_info.html_text"></div>
                <div v-else-if="selectedContent.block_info.html_text && showSubDivs">
                  <div v-for="(subContent, index) in selectedContentDivs" :key="index">
                    <div v-if="subContent.match(/^<(h[1-6]|p)>.*<\/(h[1-6]|p)>$/)" v-html="subContent"
                      :class="{ 'sub-content-block': true, 'highlighted': selectedContentDivsHighlighted.includes(index) }"
                      @click="toggleSelection(index)"></div>
                  </div>
                </div>
                <div v-else>No HTML content available for this block.</div>
              </template>
              <template v-else>
                <div class="rich-text-editor">
                  <quill-editor v-model="editedBlockContent" :options="{
                    theme: 'snow',
                    placeholder: 'Edit HTML content here',
                    modules: {
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                      ]
                    }
                  }"></quill-editor>
                </div>
              </template>
            </div>
          </div>
        </div>
        <BlockQuiz style="width: 340px;" ref="blockQuiz" v-if="selectedContent" :block_id="selectedContent._id.$id"></BlockQuiz>

        <div class="operation-panel"
          style="position: fixed; top: 70px; right: 20px; z-index: 1000; display: flex; flex-direction: column;">
          <div style="display: flex;gap: 0px;">
            <el-button size="small" v-if="!isContentEditing" type="primary" @click="startContentEditing2" icon="el-icon-edit"
              style="margin-bottom: 10px;">
              Edit Content
            </el-button>
            <el-button size="small" v-if="isContentEditing" type="success" @click="saveBlockContent" icon="el-icon-check">
              Save Content
            </el-button>
            <el-button size="small" type="primary" v-if="selectedContent" @click="doAddQuiz" style="margin-bottom: 10px;">Add Quiz</el-button>
            <el-button size="small" @click="toggleContentView" :type="showSubDivs ? 'primary' : ''" style="margin-bottom: 10px;">
              {{ showSubDivs ? 'Show Full HTML' : 'Show Sub Divs' }}
            </el-button>
          </div>
         
        </div>
      </el-container>
    </el-container>

    <el-dialog :visible.sync="showRemoveDialog" :title="'删除' + removeInfo.content_type" width="45%">
      <div>
        <el-form>
          <el-form-item label="删除类型">
            <el-select v-model="removeInfo.remove_type">
              <el-option label="仅索引" value="index"></el-option>
              <el-option label="索引以及内容" value="All"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="showRemoveDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmRemove">确 定</el-button>
        </div>
      </div>

    </el-dialog>

    <el-dialog title="AI Editor" :visible.sync="showAIEditorDialog" width="70%" :fullscreen="false"
      custom-class="ai-editor-dialog" :append-to-body="true">
      <div class="ai-editor-container" style="height: calc(100vh - 300px); display: flex; flex-direction: column;">
        <div>
          <el-select v-model="currentMode" size="medium">
            <el-option v-for="item in modelList" :key="item" :value="item"></el-option>
          </el-select>
        </div>
        <div class="ai-dialogue"
          style="width: 100%; height: calc(100% - 100px); box-sizing: border-box; padding: 10px; overflow-y: auto; margin-bottom: 100px;">
          <div style="padding: 10px; box-sizing: border-box; position: relative;" :class="{assistant: item.role === 'assistant'}" v-for="(item, index) in aiResult" :key="index">
            
            <div v-if="item.role === 'assistant'" class="assistant-actions" style="margin-top: 00px;">
              <el-button size="mini" @click="copyAssistantContent(item.content)" type="primary" style="margin-right: 5px;">
                Copy
              </el-button>
              <el-button size="mini" @click="overwriteContent(item.content)" type="success">
                Overwrite
              </el-button>
            </div>
            <div v-html="item.content ? changeToHtml(item.content) : '生成中...'" style="margin-top: 30px;"></div>
          </div>
        </div>
        <div class="ai-input"
          style="padding: 20px; background-color: #f5f5f5; position: absolute; bottom: 0; left: 0; right: 0;">
          <el-input :disabled="isGenerate" v-model="aiInputMessage" placeholder="Type your message here..."
            @keyup.enter.native="sendAIMessage" :rows="4" type="textarea">
          </el-input>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { postRequest } from "@/service.js";
import { quillEditor } from 'vue-quill-editor';
import { fetchEventSource } from "@microsoft/fetch-event-source";
import BlockQuiz from "../../components/BlockQuiz.vue";
// Remove these individual CSS imports
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';

export default {
  components: {
    BlockQuiz,
    quillEditor
  },
  data() {
    return {
      modelList: [
        'gpt-4',
        'gpt-4o',
        'gpt-4o-mini',
        'gpt-4-turbo',
        'gpt-3.5',
        'moonshot-v1-8k',
        'moonshot-v1-32k',
        'moonshot-v1-128k',
        'o1-preview',
        'o1-mini',
        'claude-3-5-sonnet-20240620',
        'claude-3-opus-20240229',
        'claude-3-sonnet-20240229',
        'claude-3-haiku-20240307'
      ],
      currentMode: 'gpt-4',
      classInfo: {
        class_info: {}
      },
      classContents: [],
      activeIndex: '0',
      selectedContent: null,
      isMenuFolded: false,
      isEditing: false,
      isContentEditing: false,
      editedBlockName: '',
      editedBlockContent: '',
      isClassNameEditing: false,
      editedClassName: '',
      isSessionEditing: false,


      removeInfo: {
        remove_type: '',
        id: '',
        content_type: '',
        session_index: 0,
        content_index: 0,
        block_index: 0
      },
      showRemoveDialog: false,
      showSubDivs: false,
      selectedContentDivs: [],
      selectedContentDivsHighlighted: [],
      showAIEditorDialog: false,
      aiInputMessage: '',
      lastKeyPressTime: 0,
      doublePressDelay: 300, // milliseconds
      contentForAIEdit: '',
      aiResult: [],
      isGenerate: false
    }
  },
  methods: {

    doAddQuiz(){
      this.$refs['blockQuiz'].addNewQuiz()
    },

    changeToHtml(data) {
      if (data) {
        if (data.indexOf("```") !== -1) {
          const count = data.split("```").length - 1;
          if (count % 2 === 0) {
            return window.marked.parse(data);
          } else {
            return window.marked.parse(data + "\n\n```");
          }
        } else {
          return window.marked.parse(data);
        }
      } else {
        return "";
      }
    },
    confirmRemove() {
      if (this.removeInfo.remove_type == '') {
        this.$message.warning('请选择删除类型')
        return
      }
      this.$alert('你确定要删除吗？，如果选择了删除索引以及内容，所有数据都被删除，无法找回', '提示', {
        callback: action => {
          if (action == 'confirm') {
            this.doConfirmedRemoveContent()
          }
        }
      })
    },
    doConfirmedRemoveContent() {
      if (this.removeInfo.content_type == 'block') {
        postRequest("removeBlock", {
          block_id: this.removeInfo.id,
          remove_type: this.removeInfo.remove_type
        }).then((response) => {
          this.classContents[this.removeInfo.session_index].contents[this.removeInfo.content_index].blocks.splice(this.removeInfo.block_index, 1)
          this.showRemoveDialog = false
          this.$message.success('删除成功~')
        })
      } else if (this.removeInfo.content_type == 'content') {
        postRequest("removeContent", {
          content_id: this.removeInfo.id,
          remove_type: this.removeInfo.remove_type
        }).then((response) => {
          this.classContents[this.removeInfo.session_index].contents.splice(this.removeInfo.content_index, 1)
          this.showRemoveDialog = false
          this.$message.success('删除成功~')
        })
      } else if (this.removeInfo.content_type == 'session') {
        postRequest("removeSession", {
          session_id: this.removeInfo.id,
          remove_type: this.removeInfo.remove_type
        }).then((response) => {
          this.classContents.splice(this.removeInfo.session_index, 1)
          this.showRemoveDialog = false
          this.$message.success('删除成功~')
        })
      }
    },

    doRemoveSession(index) {
      let session = this.classContents[index]
      let session_id = session._id.$id
      this.removeInfo = {
        content_type: 'session',
        id: session_id,
        remove_type: '',
        session_index: index,
        content_index: 0,
        block_index: 0
      }
      this.showRemoveDialog = true
    },

    doRemoveContent(index, contentIndex) {
      let content = this.classContents[index].contents[contentIndex]
      let content_id = content._id.$id
      this.removeInfo = {
        content_type: 'content',
        id: content_id,
        remove_type: '',
        session_index: index,
        content_index: contentIndex,
        block_index: 0
      }
      this.showRemoveDialog = true
    },
    doRemoveBlock(index, contentIndex, blockIndex) {
      let block = this.classContents[index].contents[contentIndex].blocks[blockIndex]
      let block_id = block._id.$id
      this.removeInfo = {
        content_type: 'block',
        id: block_id,
        remove_type: '',
        session_index: index,
        content_index: contentIndex,
        block_index: blockIndex
      }
      this.showRemoveDialog = true
    },

    handleSelect(index, indexPath) {
      this.activeIndex = index
    },
    toggleFoldUnfold() {
      this.isMenuFolded = !this.isMenuFolded;
      const menuItems = this.$refs.menu.$children;
      menuItems.forEach(item => {
        if (item.$options.name === 'ElSubmenu') {
          item.collapse = this.isMenuFolded;
        }
      });
    },
    selectContentAndScrollToTop(block) {
      this.selectedContent = block;
      console.log('this.selectedContent :' + JSON.stringify(this.selectedContent))
      this.selectedContentDivs = this.breakHtmlIntoSubContents(block.block_info.html_text);
      console.log('selectedContentDivs', this.selectedContentDivs)
      this.$nextTick(() => {
        const mainContent = document.querySelector('.main-content');
        mainContent.scrollTop = 0;
      });
      //显示block quiz components
      // this.doRequestBlockQuiz(block._id.$id)
    },
    breakHtmlIntoSubContents(htmlText) {
      const parts = htmlText.split(/(<h[1-6]>.*?<\/h[1-6]>|<p>.*?<\/p>)/);
      return parts.filter(part => {
        const trimmedPart = part.trim();
        return trimmedPart !== '' && !trimmedPart.startsWith('<br');
      });
    },
    toggleEditMode() {
      if (!this.isEditing) {
        this.isEditing = !this.isEditing;
        this.editedBlockName = this.selectedContent.block_info.block_name;
      }

    },
    saveBlockName2() {
      this.isEditing = false;
      this.selectedContent.block_info.block_name = this.editedBlockName;

      postRequest('updateBlock', this.selectedContent)
        .then((response) => {
          // Handle successful update
          console.log('Block updated successfully:', response);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating block:', error);
        });
    },

    saveBlockContent() {
      this.isContentEditing = false;
      this.selectedContent.block_info.html_text = this.editedBlockContent;
      this.selectedContentDivs = this.breakHtmlIntoSubContents(this.editedBlockContent);

      postRequest('updateBlock', this.selectedContent)
        .then((response) => {
          // Handle successful update
          console.log('Block updated successfully:', response);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating block:', error);
        });
    },
    startContentEditing2() {
      if (!this.isContentEditing) {
        this.isContentEditing = true;
        this.editedBlockContent = this.selectedContent.block_info.html_text;
        // You might want to create a deep copy of the content here
        // to allow for cancellation of edits
        // this.editedContent = JSON.parse(JSON.stringify(this.selectedContent));
      }
    },
    startClassNameEditing() {
      if (!this.isClassNameEditing) {
        this.isClassNameEditing = true;
        this.editedClassName = this.classInfo.class_info.class_name;
      }
    },
    saveClassName() {
      this.isClassNameEditing = false;
      this.classInfo.class_info.class_name = this.editedClassName;

      postRequest('updateClass', this.classInfo)
        .then((response) => {
          // Handle successful update
          console.log('Class updated successfully:', response);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating class:', error);
        });
    },
    startSessionEditing(index) {
      console.log('startSessionEditing', index)
      this.$set(this.classContents[index], 'isEditing', true);
      this.$set(this.classContents[index], 'editedSessionName', this.classContents[index].session_info.session_name);
      this.$nextTick(() => {
        if (this.$refs.sessionNameInput && this.$refs.sessionNameInput[index]) {
          this.$refs.sessionNameInput[index].focus();
        }
      });
    },
    saveSessionName(index) {
      const session = this.classContents[index];
      session.isEditing = false;
      session.session_info.session_name = session.editedSessionName;

      postRequest('updateSession', session)
        .then((response) => {
          console.log('Session updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating session:', error);
        });
    },
    startContentEditing(index, contentIndex) {
      console.log('startContentEditing', index, contentIndex)
      this.$set(this.classContents[index].contents[contentIndex], 'isEditing', true);
      this.$set(this.classContents[index].contents[contentIndex], 'editedContentName', this.classContents[index].contents[contentIndex].content_info.content_name);
      this.$nextTick(() => {
        if (this.$refs.contentNameInput && this.$refs.contentNameInput[contentIndex]) {
          this.$refs.contentNameInput[contentIndex].focus();
        }
      });
    },
    saveContentName(index, contentIndex) {
      const content = this.classContents[index].contents[contentIndex];
      content.isEditing = false;
      content.content_info.content_name = content.editedContentName;

      console.log('saveContentName', content)
      postRequest('updateContent', content)
        .then((response) => {
          console.log('Content updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating content:', error);
        });
    },
    startBlockEditing(index, contentIndex, blockIndex) {
      console.log('startBlockEditing', index, contentIndex, blockIndex);
      const block = this.classContents[index].contents[contentIndex].blocks[blockIndex];
      this.$set(block, 'isEditing', true);
      this.$set(block, 'editedBlockName', block.block_info.block_name);
      this.$nextTick(() => {
        if (this.$refs.blockNameInput && this.$refs.blockNameInput[0]) {
          this.$refs.blockNameInput[0].focus();
        }
      });
    },
    saveBlockName(index, contentIndex, blockIndex) {
      const block = this.classContents[index].contents[contentIndex].blocks[blockIndex];
      block.isEditing = false;
      block.block_info.block_name = block.editedBlockName;

      postRequest('updateBlock', block)
        .then((response) => {
          console.log('Block updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating block:', error);
        });
    },
    moveBlockDown(index, contentIndex, blockIndex) {
      console.log('moveBlockDown', index, contentIndex, blockIndex);
      const session = this.classContents[index];
      const blocks = session.contents[contentIndex].blocks;
      const block_ids = session.contents[contentIndex].block_ids;

      if (blockIndex < blocks.length - 1) {
        const temp = blocks[blockIndex];
        this.$set(this.classContents[index].contents[contentIndex].blocks, blockIndex, blocks[blockIndex + 1]);
        this.$set(this.classContents[index].contents[contentIndex].blocks, blockIndex + 1, temp);

        const temp_id = block_ids[blockIndex];
        this.$set(this.classContents[index].contents[contentIndex].block_ids, blockIndex, block_ids[blockIndex + 1]);
        this.$set(this.classContents[index].contents[contentIndex].block_ids, blockIndex + 1, temp_id);

      }

      postRequest('updateContent', this.classContents[index].contents[contentIndex])
        .then((response) => {
          console.log('Content updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating content:', error);
        });
    },
    moveBlockUp(index, contentIndex, blockIndex) {
      console.log('moveBlockUp', index, contentIndex, blockIndex);
      const session = this.classContents[index];
      const blocks = session.contents[contentIndex].blocks;
      const block_ids = session.contents[contentIndex].block_ids;

      if (blockIndex > 0) {
        const temp = blocks[blockIndex];
        this.$set(this.classContents[index].contents[contentIndex].blocks, blockIndex, blocks[blockIndex - 1]);
        this.$set(this.classContents[index].contents[contentIndex].blocks, blockIndex - 1, temp);

        const temp_id = block_ids[blockIndex];
        this.$set(this.classContents[index].contents[contentIndex].block_ids, blockIndex, block_ids[blockIndex - 1]);
        this.$set(this.classContents[index].contents[contentIndex].block_ids, blockIndex - 1, temp_id);
      }

      postRequest('updateContent', this.classContents[index].contents[contentIndex])
        .then((response) => {
          console.log('Content updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating content:', error);
        });
    },
    moveContentDown(index, contentIndex) {
      console.log('moveContentDown', index, contentIndex);
      const session = this.classContents[index];
      const contents = session.contents;
      const content_ids = session.content_ids;

      if (contentIndex < contents.length - 1) {
        const temp = contents[contentIndex];
        this.$set(this.classContents[index].contents, contentIndex, contents[contentIndex + 1]);
        this.$set(this.classContents[index].contents, contentIndex + 1, temp);

        const temp_id = content_ids[contentIndex];
        this.$set(this.classContents[index].content_ids, contentIndex, content_ids[contentIndex + 1]);
        this.$set(this.classContents[index].content_ids, contentIndex + 1, temp_id);
      }

      postRequest('updateSession', this.classContents[index])
        .then((response) => {
          console.log('Session updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating session:', error);
        });
    },
    moveContentUp(index, contentIndex) {
      console.log('moveContentUp', index, contentIndex);
      const session = this.classContents[index];
      const contents = session.contents;
      const content_ids = session.content_ids;

      if (contentIndex > 0) {
        const temp = contents[contentIndex];
        this.$set(this.classContents[index].contents, contentIndex, contents[contentIndex - 1]);
        this.$set(this.classContents[index].contents, contentIndex - 1, temp);

        const temp_id = content_ids[contentIndex];
        this.$set(this.classContents[index].content_ids, contentIndex, content_ids[contentIndex - 1]);
        this.$set(this.classContents[index].content_ids, contentIndex - 1, temp_id);
      }

      postRequest('updateSession', this.classContents[index])
        .then((response) => {
          console.log('Session updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating session:', error);
        });
    },
    moveSessionDown(index) {
      console.log('moveSessionDown', index);
      const sessions = this.classContents;
      const session_ids = this.classInfo.session_ids;

      if (index < sessions.length - 1) {
        const temp = sessions[index];
        this.$set(this.classContents, index, sessions[index + 1]);
        this.$set(this.classContents, index + 1, temp);

        const temp_id = session_ids[index];
        this.$set(this.classInfo.session_ids, index, session_ids[index + 1]);
        this.$set(this.classInfo.session_ids, index + 1, temp_id);
      }

      postRequest('updateClass', this.classInfo)
        .then((response) => {
          console.log('Session updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating session:', error);
        });
    },
    moveSessionUp(index) {
      console.log('moveSessionUp', index);
      const sessions = this.classContents;
      const session_ids = this.classInfo.session_ids;

      if (index > 0) {
        const temp = sessions[index];
        this.$set(this.classContents, index, sessions[index - 1]);
        this.$set(this.classContents, index - 1, temp);

        const temp_id = session_ids[index];
        this.$set(this.classInfo.session_ids, index, session_ids[index - 1]);
        this.$set(this.classInfo.session_ids, index - 1, temp_id);
      }

      postRequest('updateClass', this.classInfo)
        .then((response) => {
          console.log('Session updated successfully:', response);
        })
        .catch((error) => {
          console.error('Error updating session:', error);
        });
    },

    addNewSession() {
      console.log('addNewSession');
      // const newSession = {
      //   session_info: {
      //     session_name: 'New Session',
      //     class_id: this.class_id
      //   },
      //   contents: [],
      //   content_ids: [],
      //   isEditing: true,
      //   editedSessionName: 'New Session',
      //   _id: []
      // };
      const newSession = {
        session_info: {
          session_name: 'New Session',
          class_id: this.class_id
        },
        content_ids: [],
        _id: []
      };
      // this.classContents.push(newSession);
      postRequest('updateSession', newSession)
        .then((response) => {
          let session = response.data
          session['isEditing'] = false
          session['editedSessionName'] = 'New Session'
          session['contents'] = []
          session['content_ids'] = []
          this.classContents.push(session);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating block:', error);
        });
    },

    addNewContent(index) {
      console.log('addNewContent', index);
      // const newContent = {
      //   content_info: {
      //     content_name: 'New Content',
      //     session_id: this.classContents[index]._id.$id
      //   },
      //   blocks: [],
      //   block_ids: [],
      //   isEditing: true,
      //   editedContentName: 'New Content',
      //   _id: []
      // };
      const newContent = {
        content_info: {
          content_name: 'New Content',
          session_id: this.classContents[index]._id.$id,
          class_id: this.class_id
        },
        block_ids: [],
        _id: []
      };
      postRequest('updateContent', newContent)
        .then((response) => {
          let content = response.data
          content['isEditing'] = false
          content['block_ids'] = []
          content['blocks'] = []
          content['editedContentName'] = 'New Content'
          if (!this.classContents[index].contents) {
            this.classContents[index].contents = []
          }
          this.classContents[index].contents.push(content);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating block:', error);
        });
      // this.classContents[index].contents.push(newContent);

    },

    addNewBlock(index, contentIndex) {
      console.log('addNewBlock', index, contentIndex);
      const newBlock = {
        block_info: {
          block_name: 'New Block',
          content_id: this.classContents[index].contents[contentIndex]._id.$id,
          session_id: this.classContents[index]._id.$id,
          class_id: this.class_id
        },
        _id: []
      };
      postRequest('updateBlock', newBlock)
        .then((response) => {
          let block = response.data
          block['isEditing'] = false
          block['editedBlockName'] = 'New Block'
          if (!this.classContents[index].contents[contentIndex].blocks) {
            this.classContents[index].contents[contentIndex].blocks = []
          }
          this.classContents[index].contents[contentIndex].blocks.push(block);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating block:', error);
        });
      // this.classContents[index].contents[contentIndex].blocks.push(newBlock);

    },
    toggleContentView() {
      this.showSubDivs = !this.showSubDivs;
    },
    toggleSelection(index) {
      if (this.selectedContentDivsHighlighted.includes(index)) {
        this.selectedContentDivsHighlighted = this.selectedContentDivsHighlighted.filter(i => i !== index);
      } else {
        this.selectedContentDivsHighlighted.push(index);
      }

      console.log('selectedContentDivsHighlighted', this.selectedContentDivsHighlighted)
    },

    showAIEditor() {
      this.aiResult = [];
      console.log('showAIEditor')
      if (this.selectedContent !== null && this.selectedContent !== '') {
        this.showAIEditorDialog = !this.showAIEditorDialog;
      } else {
        this.$message.warning('请先选中一个内容块')
      }

      if (this.showAIEditorDialog) {
        if (this.selectedContentDivsHighlighted.length === 0) {
          // Edit the whole content if no divs are highlighted
          this.contentForAIEdit = this.selectedContent.block_info.html_text;
        } else {
          // Combine highlighted divs if any are selected
          this.contentForAIEdit = this.selectedContentDivsHighlighted
            .map(index => this.selectedContentDivs[index])
            .join('\n');
        }

        this.$nextTick(() => {
            const aiInputElement = this.$refs.aiInput;
            if (aiInputElement) {
              aiInputElement.focus();
            }
          });
      }
    },

    sendAIMessage() {
      console.log('sendAIMessage', this.aiInputMessage)
      let promptContext = 'Return your result in HTML format.'
      
      let userMessage = {
        role: "user",
        content: promptContext + this.aiInputMessage + 'The content to edit is: ' + this.contentForAIEdit,
      };
      let userMessageSimple = {
        role: "user",
        content: this.aiInputMessage,
      };
      let messages = [
        ...this.aiResult,
        userMessage
      ];
      this.aiResult.push(userMessageSimple);
      this.aiResult.push({
        role: 'assistant',
        content: ''
      });
      this.aiInputMessage = '';
      this.isGenerate = true
      let that = this
      const eventSource = fetchEventSource(
        "https://web-backend-sg.reachable-edu.com/AIChat/Test.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          openWhenHidden: true,
          body: JSON.stringify({
            model: that.currentMode,
            messages,
          }),
          onopen(response) {
            console.log("连接已建立", response);
          },
          onmessage(event) {
            if (that.currentMode.indexOf("claude") != -1 && event.data) {
              const result = JSON.parse(event.data);
              if (result.type === "message_delta") {
                that.isGenerate = false
                return;
              }
            } else if (event.data === "Connection closed") {
              that.isGenerate = false
              return;
            }
            console.log("收到消息", JSON.stringify(event.data));
            const result = JSON.parse(event.data);
            if (that.currentMode.indexOf("claude") != -1) {
              if (result.type === "content_block_delta") {
                that.aiResult[that.aiResult.length - 1].content += result.delta.text;
              }
            } else if (result.time && result.content) {
              that.aiResult[that.aiResult.length - 1].content += result.content;
            }
          },
          onclose() {
            that.isGenerate = false
          },
          onerror() {
            that.isGenerate = false
          },
        }
      );
    },

    handleKeyDown(event) {
      if (event.key === 'l') {
        console.log('handleKeyDown', event.key);
        const currentTime = new Date().getTime();

        if (currentTime - this.lastKeyPressTime <= this.doublePressDelay) {
          event.preventDefault(); // Prevent default browser behavior
          this.showAIEditor();

          
        }

        this.lastKeyPressTime = currentTime;

        

        
      } else if (event.key === 'f') {
        const currentTime = new Date().getTime();

        if (currentTime - this.lastKeyPressTime <= this.doublePressDelay) {
          event.preventDefault(); // Prevent default browser behavior
          this.toggleContentView();
        }

        this.lastKeyPressTime = currentTime;
      }
    },

    

    copyAssistantContent(content) {
      console.log('copyAssistantContent', content)
      navigator.clipboard.writeText(content);
      this.$message.success('已复制到剪贴板');
    },

    overwriteContent(content) {
      console.log('overwriteContent', content)

     
      if (this.selectedContentDivsHighlighted.length > 0) {
   
        let updateIndex = this.selectedContentDivsHighlighted[0];
        // Remove the highlighted divs from selectedContentDivs
        this.selectedContentDivsHighlighted.sort((a, b) => b - a); // Sort in descending order
        for (let index of this.selectedContentDivsHighlighted) {
          this.selectedContentDivs.splice(index, 1);
        }

        // Insert the new content at the first highlighted index
        this.selectedContentDivs.splice(updateIndex, 0, content);

        // Reconstruct the html_text
        this.selectedContent.block_info.html_text = this.selectedContentDivs.join('');
        // Update selectedContentDivs to reflect the changes
        this.selectedContentDivs = this.selectedContent.block_info.html_text.split(/(<h[1-6]>.*?<\/h[1-6]>|<p>.*?<\/p>)/).filter(Boolean);

        // Update the content on the server
        postRequest('updateBlock', this.selectedContent)
          .then((response) => {
            console.log('Block updated successfully:', response);
            this.$message.success('Content overwritten successfully');
          })
          .catch((error) => {
            console.error('Error updating block:', error);
            this.$message.error('Failed to update content');
          });

        // Clear the highlighted indexes
        this.selectedContentDivsHighlighted = [];

        this.showAIEditorDialog = false;

      } else if (this.selectedContent   ) {
        // Replace the entire html_text content
        this.selectedContent.block_info.html_text = content;
        // Update selectedContentDivs to reflect the new content
        this.selectedContentDivs = this.selectedContent.block_info.html_text.split(/(<div[^>]*>.*?<\/div>)/gs).filter(Boolean);
        // Update the content on the server
        postRequest('updateBlock', this.selectedContent)
        .then((response) => {
          // Handle successful update
          console.log('Block updated successfully:', response);
        })
        .catch((error) => {
          // Handle error
          console.error('Error updating block:', error);
        });

        this.$message.success('Content overwritten successfully');

        this.showAIEditorDialog = false;

      
      } else {
        this.$message.error('No content selected for overwriting');
      }
    },
  },

  mounted() {
    // ... existing mounted code ...
    const id = this.$router.currentRoute.query.class_id;
    this.class_id = id;
    this.loading = true;
    this.$nextTick(() => {
      this.$loading({
        lock: true,
        text: 'Loading class contents...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    });
    postRequest('getClassContents', {
      class_id: id,
    }).then((res) => {
      console.log('result111:' + JSON.stringify(res.sessions))
    let result = res.sessions
    result.map((session)=>{
        if(!session.contents){
          session.contents = []
        }
        if(!session.content_ids){
          session.content_ids = []
        }
        session.contents.map((content)=>{
          if(!content.blocks){
            content.blocks = []
          }
          if(!content.block_ids){
            content.block_ids = []
          }
          content.blocks.map((block)=>{
            block.isEditing = false
            return block
          })
          content.isEditing = false
          return content
        })
        session.isEditing  = false
        return session
      })
      this.classContents = result
      // console.log('this.classContents  :' + JSON.stringify(this.classContents ))
      this.classInfo = res.class_info
      // Close the loading modal
      this.$nextTick(() => {
        this.$loading().close();
      });
      this.loading = false;
    })


    // Add event listener for 'l' key
    document.addEventListener('keydown', this.handleKeyDown);

  },

  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener('keydown', this.handleKeyDown);
  },
}
</script>
<style scoped lang="scss">
.el-container {
  height: 100vh;
}

.el-header {
  background-color: #B3C0D1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  width: 200px !important;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  padding: 20px;
}

.el-menu-item {
  font-size: 14px;
}

h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
}

h4 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
}

p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.highlighted {
  background-color: #ffffd0;
}

.sub-content-block {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.assistant{
  background: #eee;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}
.assistant-actions {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}



/* Add these imports here */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';
</style>